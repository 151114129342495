<template>
  <section class="pnf">
    <div class="hero-body">
      <div class="container centered">
        <h1 class="title">
          <p>404</p>
        </h1>
        <h2 class="subtitle">
          <p>
            Uncharted territory! We couldn't find what you were looking for.
          </p>
          <p>
            Please navigate to the
            <span @click="goToHome()"><u>homepage</u></span>
          </p>
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
/** This component is displayed when the URL entered does not match any paths in the router */
import { mapState } from 'vuex'
import { getModuleToNavigate } from '@/util/util.js'
export default {
  computed: {
    ...mapState('user', ['client_logos', 'userDetails'])
  },
  methods: {
    goToHome () {
      this.$router.replace(getModuleToNavigate(this.userDetails))
    }
  }
}
</script>

<style lang="scss" scoped>
.pnf {
  position: absolute;
  left: 0px;
  background: var(--primary);
  height: 100vh;
  width: 100vw;

  .title:not(.is-spaced) + .subtitle {
    margin-top: 1.25rem;
  }

  .title {
    p {
      font-size: 100px;
      color: var(--primary-color);
    }
  }
  .subtitle {
    p {
      color: var(--primary-color);
    }
  }
  u {
    cursor: pointer;
  }
  .centered {
    top: 50px;
    text-align: center;
  }
}
</style>
